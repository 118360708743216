import { Component } from '@angular/core';
import { Notification } from '@common/notifications/notification.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'u-notification-generic-interrupt',
  templateUrl: './notification-generic-interrupt.component.html',
  styleUrls: ['./notification-generic-interrupt.component.scss'],
})
export class NotificationGenericInterruptComponent {
  data: { notification: Notification };

  constructor(private modal: NgbActiveModal) {}

  onClose(): void {
    this.modal.close();
  }

  trackByIndex(index: number): number {
    return index;
  }
}
