<universal-modal-base>
  <ng-container modalHeader>
    <h2 [innerHTML]="data.notification.category.displayText"></h2>
  </ng-container>

  <ng-container modalBody>
    <ng-container *ngFor="let notificationData of data.notification.content; trackBy: trackByIndex">
      <div *ngIf="notificationData.part === 'BODY'">
        <div class="notificationContent" [innerHTML]="notificationData.text"></div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container modalFooter>
    <universal-modal-buttons>
      <universal-button (click)="onClose()" [data]="{ type: 'tertiary', automationId: 'generic-close' }">
        {{ 'close' | translate }}
      </universal-button>
    </universal-modal-buttons>
  </ng-container>
</universal-modal-base>
